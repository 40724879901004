.viz-box-shadow-special {
  box-shadow: $global-special-box-shadow;
}

.viz-box-shadow-special-hover {
  &:hover {
    &.scale-up {
      transform: scale(1.005);
    }
    box-shadow: $global-special-box-shadow;
  }
}

.viz-primary-shadow {
  box-shadow: 0 0 0 1px #ed174b;
}
