@import "variables";

@import "uikit/src/scss/mixins-theme.scss";

@import "uikit/src/scss/uikit-theme.scss";

@import "./components/button";
@import "./components/input";
@import "./components/tooltip";
@import "./components/alert";
@import "./components/boxShadow";
@import "./components/card";
@import "./components/user";
@import "./components/actionable";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Minion:400,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@700&family=Heebo:wght@700&family=Rubik:wght@500&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

/**
 TO BREAK APART LATER
**/
.uk-iconnav {
  .uk-text-primary {
    &.uk-icon {
      svg {
        polygon {
          fill: #ed174b;
        }
      }
    }
  }
}
.viz-text-line-through {
  text-decoration: line-through;
}
.pac-container {
  z-index: 10000 !important;
}
.viz-border-1 {
  border: 1px solid $global-border;
  transition: all 200ms;
  &.primary-border {
    border-color: $global-primary-background;
  }
  &.uk-box-shadow-hover-small,
  &.uk-box-shadow-hover-medium,
  &.uk-box-shadow-hover-large,
  &.viz-box-shadow-special-hover {
    &:hover {
      border-color: #fff;
    }
  }
}

.viz-bg-white {
  background-color: #fff;
}

.viz-border-remove {
  &,
  * {
    &.uk-button {
      &:hover {
        color: $global-primary-background;
      }
    }
    border: none !important;
  }
}

.viz-edit {
  .viz-icon-edit {
    margin-left: -10px !important;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
  }
  &:hover {
    .viz-icon-edit {
      margin-left: 10px !important;
      opacity: 1;
      pointer-events: all;
    }
  }
}

.viz-tabs-no-underline {
  margin-bottom: 0 !important;
  &::before {
    display: none !important;
  }
  li.uk-active {
    a {
      box-shadow: 0px 15px 10px -10px rgba(237, 23, 75, 0.3);
    }
  }
}

.uk-sticky {
  .viz-nav-title {
    visibility: hidden;
    opacity: 0;
    transition: all 200ms;
  }
  &.uk-sticky-fixed {
    box-shadow: $global-special-box-shadow;
    //width: inherit !important;
    .viz-nav-title {
      opacity: 1;
      visibility: visible !important;
    }
  }
}

.viz-actions-container {
  .viz-actions {
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
  }
  &:hover {
    .viz-actions {
      opacity: 1;
      pointer-events: all;
    }
  }
}


.patricia-ai-color {
  background-color: $patricia-ai-color !important;
}

.patricia-ai-text-color {
  color: $patricia-ai-color !important;
}

.patricia-ai-input:focus {
    border-color: $patricia-ai-color !important;
}

.patricia-ai-border {
  border-color: $patricia-ai-color !important;
  &:hover{
    box-shadow: none;
  }
}

.patricia-ai-hebrew-title-font {
  font-family: 'Rubik', sans-serif;
}

.quiz-question-english-font {
  font-family: Minion;
}
.quiz-question-hebrew-font {
  font-family: 'Open Sans', sans-serif;
}

.quarter-circle-bottom-left{
  width: 100px;
  height: 100px;
  border-radius: 0 100px 0 0;
  -moz-border-radius: 0 100px 0 0;
  -webkit-border-radius: 0 100px 0 0;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  box-shadow: 5px 5px lightgrey;
  margin-left: 20px;
  &:hover{
    box-shadow: none;
  }
  &.selected{
    opacity: 0.5;
    box-shadow: none;
  }
}
.quarter-circle-bottom-right{
  width: 100px;
  height: 100px;
  border-radius: 100px 0 0 0;
  -moz-border-radius: 100px 0 0 0;
  -webkit-border-radius: 100px 0 0 0;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  box-shadow: -5px 5px lightgrey;
  margin-left: 20px;
  &:hover{
    box-shadow: none;
  }
  &.selected{
    opacity: 0.5;
    box-shadow: none;
  }
}

.selected-answer{
  border: 2px solid red;
  border-radius: 5px;
}

.quiz-button {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.patricia-hebrew-font{
   * {
     font-family: 'Varela Round', sans-serif;
   }
}

.reg-progress-step {
  border: 2px black solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  &.active {
    color: $patricia-ai-color;
    border: 2px $patricia-ai-color solid;
  }

  &.done {
    color: white;
    background-color:  $patricia-ai-color;
    border: 2px $patricia-ai-color solid;
  }

  &.future {
    color: grey;
    background-color: lightgrey;
  }
}

.reg-progress-bar-line{
  width: 1px;
  height: 4px;
  align-self: center;
  border-right: 285px solid #545454;
  textAlign: left;

  &.done {
    border-right: 285px solid $patricia-ai-color !important;
  }
}

.reg-next-button {
  color: $patricia-ai-color;
  border-radius: 16px;
  padding: 5px;
  font-size: 24px;
  border: 2px solid $patricia-ai-color;
  &:hover {
    background-color: $patricia-ai-color;
    color: white;
  }

}

.patricia-hebrew-header-font {
  font-family: 'Secular One', sans-serif;
}

.children-small-margin-bottom {
  * {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.patricia-product-modal {
  display: block;
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 50% 50%
  }
}

.quiz-color-palette-circle {
  flex: 0 0 20%; /*flex-grow | flex-shrink | flex-basis*/
  box-shadow: 0 0 10px #424242;
  border-radius: 50%;
  width: 120px;
  height: 100%;
  @media (max-width: 960px) {
    width: 30px;
    height: 30px;
    flex: 0 0 10%;
    padding: 0.5em;
  }
}