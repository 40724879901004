.viz-actionable {
  transition: all 100ms;
  z-index: 1;
  &.viz-aggr {
    display: none;
  }
  .viz-actionable-actions {
    background: #fff;
    display: block;
    position: absolute;
    left: -35px;
    top: -5px;
    width: 40px;
    height: auto;
    max-height: 100px;
    z-index: 1 !important;
    opacity: 0;
    transition: all 200ms;
    border-radius: 5px;
    z-index: 3 !important;
    padding-top: 10px;
    transform: scale(0.6) translatex(80px);
    &.actions-center {
      padding-top: 0;
      left: -20px;
      height: 80px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    & > ul {
      vertical-align: top;
      padding: 0;
      & > li {
        padding: 10px !important;
        & > a {
          font-size: 30px !important;
        }
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba($global-muted-color, 0.3);
        }
      }
    }
  }
  &:hover {
    z-index: 2;
    .viz-actionable-actions {
      transform: none;
      opacity: 1;
    }
  }
}

li.viz-compact-place {
  position: relative;
  & > .viz-actionable {
    &.viz-aggr {
      display: block;
      cursor: pointer;
      &:hover {
        background: transparent;
      }
      & > div > div:first-of-type > div {
        box-shadow: 0px 3px 5px 2px rgba(#000, 0.05), 0px 10px 0 -5px #e0e0e0,
          0px 20px 0 -10px #f0f0f0;
      }
    }
    &:not(.viz-aggr) {
      display: none;
    }
    .viz-actionable-actions {
      display: none !important;
    }

    // &:not(.viz-aggr) {
    //   visibility: visible;
    //   position: absolute !important;
    //   display: block;
    //   margin-top: 7% !important;
    //   width: 100%;
    //   // left: -2%;
    //   & > .uk-grid {
    //     & > div:not(:first-of-type) {
    //       visibility: hidden;
    //     }
    //     & > div:first-of-type > div {
    //       box-shadow: $global-special-box-shadow;
    //     }
    //   }
    // }
    // @for $i from 3 through 8 {
    //   &:nth-of-type(#{$i}) {
    //     opacity: 1 - $i * 0.15;
    //     z-index: -$i;
    //     top: $i * 8px;
    //     & > .uk-grid {
    //       & > div:first-of-type > div {
    //         transform: scale(1- $i * 0.03);
    //       }
    //     }
    //   }
    // }
    // @for $i from 9 through 35 {
    //   &:nth-of-type(#{$i}) {
    //     visibility: hidden;
    //   }
    // }
  }
}
