.viz-profile-avatar {
  width: 50px;
  height: 50px;
  @extend .uk-background-cover;
  @extend .uk-border-circle;
}
.viz-profile-avatar-replace {
  width: 50px;
  height: 50px;
  @extend .uk-border-circle;
  @extend .uk-background-muted;
  @extend .uk-flex;
  @extend .uk-flex-middle;
  @extend .uk-flex-center;
}
