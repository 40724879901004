.uk-button {
  &.uk-button-default {
    border-width: 1px;
  }
  // border-radius: $button-border-radius;
  &.uk-button-disabled {
    @extend .uk-button-default;
    background-color: transparent;
    cursor: not-allowed;
    & > span {
      @extend .uk-text-muted;
    }
  }
}

.uk-button-group {
  & > button {
    padding: 0 15px !important;
    &:not(.uk-button-primary):hover {
      color: $global-primary-background;
    }
  }
  // & > button:first-of-type {
  //   border-radius: 5px 0 0 5px;
  // }
  // & > button:last-of-type {
  //   border-radius: 0 5px 5px 0;
  // }
}
