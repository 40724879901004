.uk-card {
  z-index: 1;
  &:hover {
    .viz-card-background {
      transform: scale(1.03);
    }
  }
  & > .viz-card-background {
    &.viz-hover {
      transform: scale(1.03);
      //   height: 500px;
    }
    @extend .uk-position-cover;
    z-index: -1;
    transition: all 200ms;
    overflow: hidden;
    // height: inherit;
    .viz-card-image {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 55%;
        height: 140%;
        display: block;
        background: #fff;
        left: -20%;
        top: -3;
        transform: skewX(10deg);
      }
      &.dir-rtl {
        &::after {
          left: auto;
          right: -20%;
          transform: skewX(-10deg);
        }
      }
    }
  }
}
